<template>
  <div>
    <el-form ref="dataForm" :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">

      <el-form-item label="查询时段">
        <el-date-picker v-model="dataForm.timeArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
          align="right">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="审核对象">
        <el-select v-model="dataForm.auditObjectTypes" placeholder="审核对象" multiple>
          <el-option v-for="item in auditObjectTypeOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="审核类型">
        <el-select v-model="dataForm.auditTypes" placeholder="审核类型" multiple>
          <el-option v-for="item in auditTypeOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="处理方式">
        <el-select v-model="dataForm.auditHandleModes" placeholder="处理方式" multiple>
          <el-option v-for="item in auditHandleModeOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="处理结果">
        <el-select v-model="dataForm.auditStatus" placeholder="处理结果" multiple>
          <el-option v-for="item in auditStatusOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item>
        <el-button @click="getDataList()" type="primary">搜索</el-button>
        <el-button @click="refresh()">重置</el-button>
      </el-form-item>
    </el-form>
    <el-button @click="exportList()" type="primary">导出</el-button>

    <el-table :data="dataList" border v-loading="dataListLoading" style="width: 100%">
      <el-table-column prop="createTime" header-align="center" align="center" label="日期">
      </el-table-column>

      <el-table-column prop="id" header-align="center" align="center" label="审核单id">
      </el-table-column>

      <el-table-column width="100px" prop="lang" header-align="center" align="center" label="语言">
        <template slot-scope="scope">
          <div>
            <el-tag v-if="scope.row.lang == 'ar'" type="success">阿拉伯语</el-tag>
            <el-tag v-else-if="scope.row.lang == 'en'" type="success">英语</el-tag>
          </div>
        </template>
      </el-table-column>


      <el-table-column prop="objectType" header-align="center" align="center" label="审核对象">
        <template slot-scope="scope">
          {{ changeDesc(scope.row.objectType, auditObjectTypeOpt) }}
        </template>
      </el-table-column>
      <el-table-column prop="objectOfReport" header-align="center" align="center" label="用户/群ID">
      </el-table-column>
      <el-table-column prop="objectName" header-align="center" align="center" label="用户/群名称">
      </el-table-column>
      <el-table-column prop="auditType" header-align="center" align="center" label="审核类型">
        <template slot-scope="scope">
          {{ changeDesc(scope.row.auditType, auditTypeOpt) }}
        </template>
      </el-table-column>
      <el-table-column prop="auditHandleMode" header-align="center" align="center" label="处理方式" width="100">
        <template slot-scope="scope">
          {{ changeDesc(scope.row.auditHandleMode, auditHandleModeOpt) }}
        </template>
      </el-table-column>

      <el-table-column prop="auditStatus" header-align="center" align="center" label="处理结果" width="100">
        <template slot-scope="scope">
          {{ changeDesc(scope.row.auditStatus, auditStatusOpt) }}
          <el-tooltip v-if="scope.row.auditStatus === -1" class="item" effect="dark" placement="top-start">
            <i class="el-icon-question" />
            <div slot="content">
              <p style="white-space: pre-wrap;">{{ scope.row.auditFailReason }}</p>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column prop="auditTime" header-align="center" align="center" label="审核时间">
      </el-table-column>

      <el-table-column prop="auditPersonName" header-align="center" align="center" label="操作人">
      </el-table-column>

    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
      :page-sizes="[5, 10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>

  </div>
</template>

<script>
import { pickerOptions } from "@/views/common/commonOpt";

export default {
  data() {
    return {
      dataForm: {
        auditObjectTypes: [],
        timeArr: [],
        auditHandleModes: [],
        auditStatus: [],
        auditTypes: [],
        createTimeStart: '',
        createTimeEnd: ''
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      pickerOptions: pickerOptions,
      auditObjectTypeOpt: [
        {
          value: 0,
          label: '用户'
        },
        {
          value: 1,
          label: '群聊'
        }
      ],
      auditStatusOpt: [
        {
          value: 1,
          label: '处理成功'
        },
        {
          value: -1,
          label: '处理失败'
        }
      ],
      auditHandleModeOpt: [
        {
          value: 'IGNORE',
          label: '忽略'
        },
        {
          value: 'REMOVE_AVATAR',
          label: '移除头像'
        },
        {
          value: 'REMOVE_NICK',
          label: '移除昵称'
        },
        {
          value: 'IM_PRIVACY',
          label: '信息私密'
        },
        {
          value: 'MUTE_GROUP_1',
          label: '禁言1天'
        },
        {
          value: 'MUTE_GROUP_3',
          label: '禁言3天'
        },
        {
          value: 'MUTE_GROUP_7',
          label: '禁言7天'
        },
        {
          value: 'DESTROY_GROUP',
          label: '封禁群'
        },
        {
          value: 'OFFLINE_PLANET',
          label: '下星球页'
        }
      ],
      auditTypeOpt: [
        {
          value: 0,
          label: '头像审核'
        },
        {
          value: 1,
          label: '昵称审核'
        },
        {
          value: 3,
          label: 'IM举报-私聊'
        },
        {
          value: 2,
          label: 'IM举报-群聊'
        },
        {
          value: 4,
          label: '群聊审核'
        },
        {
          value: 5,
          label: '用户ID审核'
        }
      ],
    }
  },
  components: {},
  computed: {
    timeDefault() {
      var date = new Date();
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + (date.getDate());
    }
  },
  mounted() {
    // 初始化查询，默认为当天
    // this.dataForm.timeArr = [this.timeDefault + ' 00:00:00', this.timeDefault + ' 23:59:59']
  },
  activated() {
    this.getDataList()
  },
  methods: {
    changeDesc(val, arr) {
      const find = arr.find(item => item.value === val);
      return find ? find.label : val;
    },
    refresh() {
      this.dataForm.auditObjectTypes = []
      this.dataForm.timeArr = []
      this.dataForm.auditHandleModes = []
      this.dataForm.auditStatus = []
      this.dataForm.auditTypes = []
      this.dataForm.createTimeStart = ''
      this.dataForm.createTimeEnd = ''
      this.pageIndex = 1
      this.pageSize = 10
      this.getDataList()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/admin/audit/queryPage'),
        method: 'post',
        data: this.$http.adornData({
          ...this.dataForm,
          createTimeStart: (this.dataForm.timeArr && this.dataForm.timeArr.length > 0) ? this.dataForm.timeArr[0] : null,
          createTimeEnd: (this.dataForm.timeArr && this.dataForm.timeArr.length > 0) ? this.dataForm.timeArr[1] : null,
          page: this.pageIndex,
          limit: this.pageSize,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list
          this.totalPage = data.page.totalCount
        } else {
          this.dataList = []
          this.totalPage = 0
          this.$message.error(data.msg)
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    exportList() {
      const exportData = {
        ...this.dataForm,
        createTimeStart: (this.dataForm.timeArr && this.dataForm.timeArr.length > 0) ? this.dataForm.timeArr[0] : null,
        createTimeEnd: (this.dataForm.timeArr && this.dataForm.timeArr.length > 0) ? this.dataForm.timeArr[1] : null
      }
      const paramJson = encodeURI(JSON.stringify(exportData).replace(/\+/g, "%2b"))
      window.open(
        this.$http.adornUrl('/admin/audit/export') + `?token=${this.$cookie.get('token')}&paramJson=${paramJson}`,
        "_blank"
      );
    }
  }
}
</script>