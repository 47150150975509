export const langOpt = [
    {
        value: 'ar',
        label: '阿拉伯语'
    },
    {
        value: 'en',
        label: '英语'
    },
    {
        value: 'all',
        label: 'ALL'
    }
];

export const pickerOptions = {
    shortcuts: [{
        text: '最近一天',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: '最近三天',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: '最近一周',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: '最近一个月',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
        }
    }],
};

export const genderList = [
    {
        value: 0,
        label: '保密'
    },
    {
        value: 1,
        label: '男'
    },
    {
        value: 2,
        label: '女'
    },
    {
        value: 3,
        label: '无'
    },
];